
import { defineComponent, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { translate } from "@/core/helpers/translate";
import {
  showErrorPopup,
  showSuccessPopup
} from "@/core/helpers/common-helper";
import ApiService from "@/core/services/ApiService";
import useRole from "@/core/services/compositions/enum/useRole";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import useUserDetail from "@/core/services/compositions/user/useUserDetail";

interface EditUser {
  name: string;
  email: string;
  role: string;
}

interface EditPassword {
  new_password: string;
  old_password: string;
  confirm_password: string;
}

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const route = useRoute();
    const { userRole } = useRole();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const dialogUserVisible = ref(false);
    const dialogPasswordVisible = ref(false);

    // breadcrumb
    setNewPageBreadcrumbs(t("management.user.edit"), [
      { title: t("management.user.edit") }
    ]);

    const editUser = reactive<EditUser>({
      name: '',
      email: '',
      role: '',
    });

    const editPassword = reactive<EditPassword>({
      new_password: '',
      old_password: '',
      confirm_password: '',
    });

    const userHash = route.params.id;

    const { data: detail, isValidating } = useUserDetail(userHash);

    watch(detail, (response) => {
      if (response?.rc == 'SUCCESS') {
        editUser.name = response?.data.name;
        editUser.email = response?.data.email;

        if(response?.data.roles.length >= 1) {
          editUser.role = response?.data.roles[0].name;
        }
      }
    });

    const resetForm = () => {
      editUser.name = '';
      editUser.email = '';
      editUser.role = '';
    }

    const submitUpdateUser = () => {
      dialogUserVisible.value = false;

      if (editUser.name == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Name cannot empty',
          message: 'Name cannot empty',
        })

        return;
      }

      if (editUser.email == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Email cannot empty',
          message: 'Email cannot empty',
        })

        return;
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        name: editUser.name,
        email: editUser.email,
        role: editUser.role,
      };

      ApiService.setHeader();
      ApiService.put(
        `app/entity/user/` + userHash,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });
        })
        .catch(({ response }) => {
          showErrorPopup({
            title: response.data.rc,
            text: response.data.message,
            message: response.data.message,
          })
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const submitUpdatePassword = () => {
      dialogPasswordVisible.value = false;

      if (editPassword.old_password == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Current Password cannot empty',
          message: 'Current Password cannot empty',
        })

        return;
      }

      if (editPassword.new_password == '') {
        showErrorPopup({
          title: 'Error',
          text: 'New Password cannot empty',
          message: 'New Password cannot empty',
        })

        return;
      }

      if (editPassword.confirm_password == '') {
        showErrorPopup({
          title: 'Error',
          text: 'COnfirm Password cannot empty',
          message: 'COnfirm Password cannot empty',
        })

        return;
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        old_password: editPassword.old_password,
        new_password: editPassword.new_password,
        confirm_password: editPassword.confirm_password,
      };

      ApiService.setHeader();
      ApiService.put(
        `app/entity/user/` + userHash,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });
        })
        .catch(({ response }) => {
          showErrorPopup({
            title: response.data.rc,
            text: response.data.message,
            message: response.data.message,
          })
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const showDialogUser = () => {
      dialogUserVisible.value = true;
    }

    const showDialogPassword = () => {
      dialogPasswordVisible.value = true;
    }

    return {
      detail,
      editUser,
      translate,
      submitUpdateUser,
      submitButton,
      resetForm,
      isValidating,
      dialogUserVisible,
      userRole,
      showDialogUser,
      editPassword,
      showDialogPassword,
      dialogPasswordVisible,
      submitUpdatePassword
    };
  },
  methods: {}
});
