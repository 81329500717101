import useRequest, { CommonResponse } from "./../useRequest";
import { computed } from "vue";

const useUserDetail = userHash => {
  const { data, ...other } = useRequest<CommonResponse<any>>(
    `/app/entity/user/${userHash}`
  );
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data;
  });

  return {
    data: enhancedData,
    ...other
  };
};

export default useUserDetail;
